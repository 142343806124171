// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';
//@import 'sections/section-temoignages';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';


a.btn-link {
    background: $color-2;
    width: 195px;
    height: 85px;
    border-radius: 6px;
    margin: 35px auto 0 auto;
    transition: 0.3s;
    h5 {
        font-weight: bold;
        font-size: $font-size-20;
        color: #C2EBFD;
        transition: 0.3s;
    }
    &:hover {
        background: #71C5F1;
        transition: 0.3s;
        h5 {
            color: $color-1;
            transition: 0.3s;
        }
    }
    &.long {
        width: 285px;
    }
}


/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-intro {
    padding: 75px 5%;
    text-align: center;
    h3 {
        font-weight: bold;
        font-size: $font-size-60;
        color: $color-2;
        padding-bottom: 50px;
    }
    p {
        width: 74%;
        margin: auto;
    }
    h4 {
        font-weight: bold;
        font-size: $font-size-20;
        color: $color-3;
        text-transform: uppercase;
    }
    @media screen and (max-width: 1485px) {
        h3 {
            br {
                display: none;
            }
        }
        @media screen and (max-width: 1420px) {
            p {
                width: 85%;
            }
            @media screen and (max-width: 1024px) {
                h3 {
                    font-size: 2.5rem;
                    padding-bottom: 35px;
                }
                p {
                    width: 100%;
                }
                @media screen and (max-width: $size-xs-max) {
                    padding: 50px 5%;
                    h3 {
                        font-size: 2.3rem;
                    }
                }
            }
        }
    }
}

#section-image {
    padding: 25px 5% 0 5%;
    @media screen and (max-width: 1024px) {
        flex-wrap: wrap;
        .image {
            width: 45%;
            margin: 0 auto;
            padding: 0 0 30px 0;
        }
        @media screen and (max-width: $size-xs-max) {
            .image {
                width: 100%;
                padding: 0 0 10px 0;
            }
        }
    }
}

#section-bloc-btn {
    padding: 50px 5% 25px 5%;
    .section-bloc {
        padding-bottom: 50px;
        .bloc {
            width: 32%;
            box-shadow: 8px 16px 32px #0000001A;
            border-radius: 16px;
            padding: 50px 50px 50px 25px;
            h3 {
                font-weight: bold;
                font-size: $font-size-30;
                color: $color-2;
                height: 80px;
            }
            .border {
                background: $color-2;
                height: 2px;
                margin-bottom: 50px;
            }
            p {
                font-weight: 500;
                font-size: $font-size-18;
                color: $color-1;
            }
        }
    }
    @media screen and (max-width: 1765px) {
        .section-bloc .bloc h3 {
            font-size: 1.35rem;
        }
        @media screen and (max-width: 1615px) {
            .section-bloc {
                .bloc {
                    padding: 50px 35px 50px 25px;
                    h3 {
                        font-size: 1.25rem;
                    }
                }
            }
            @media screen and (max-width: 1460px) {
                .section-bloc {
                    .bloc {
                        width: 35%;
                        padding: 50px 25px;
                        h3 {
                            font-size: 1.2rem;
                        }
                    }
                }
                @media screen and (max-width: 1342px) {
                    .section-bloc {
                        .bloc {
                            h3 {
                                height: 90px;
                            }
                        }
                    }
                    @media screen and (max-width: $size-md-max) {
                        .section-bloc {
                            .bloc {
                                h3 {
                                    height: 80px;
                                }
                            }
                        }
                        @media screen and (max-width: 1225px) {
                            .section-bloc {
                                flex-wrap: wrap;
                                justify-content: center;
                                .bloc {
                                    width: 49%;
                                    margin-bottom: 0.5rem;
                                    &.mx2 {
                                        margin-right: 0;
                                    }
                                    h3 {
                                        height: 65px;
                                    }
                                    .border {
                                        margin-bottom: 35px;
                                    }
                                }
                            }
                            @media screen and (max-width: $size-xs-max) {
                                .section-bloc {
                                    flex-wrap: wrap;
                                    justify-content: center;
                                    .bloc {
                                        width: 100%;
                                        margin-bottom: 0.5rem;
                                        h3 {
                                            font-size: 1.4rem;
                                            height: initial;
                                            border-bottom: 2px solid $color-2;
                                            padding-bottom: 5px;
                                            margin-bottom: 25px;
                                        }
                                        .border {
                                            display: none;
                                        }
                                    }
                                }
                                a.btn-link.long {
                                    width: 195px;
                                    margin: 0 auto;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-text-image {
    padding: 50px 5% 50px 0;
    .text-side {
        background: #C2EBFD;
        width: 55%;
        padding: 0 50px 0 5%;
        h3 {
            font-weight: bold;
            font-size: $font-size-60;
            color: $color-2;
            padding-bottom: 35px;
        }
        p {
            color: $color-1;
            width: 95%;
        }
    }
    .image-side {
        width: 45%;
        background: linear-gradient(to right, #c2ebfd 0%, #c2ebfd 10%, #145d88 10%,#145d88 100%);
        padding-top: 100px;
        padding-right: 100px;
        //border-bottom: 100px inset #fff;
    }
    @media screen and (max-width: 1600px) {
        .text-side h3 {
            font-size: 2.7rem;
        }
        @media screen and (max-width: 1500px) {
            .text-side h3 {
                font-size: 2.5rem;
            }
            @media screen and (max-width: 1200px) {
                flex-direction: column-reverse;
                padding: 50px 5%;
                .image-side {
                    width: 65%;
                    margin: 0 auto;
                    background: #145D88;
                    padding: 75px;
                }
                .text-side {
                    width: 65%;
                    margin: auto;
                    padding: 60px 5%;
                }
                @media screen and (max-width: 1024px) {
                    .image-side, .text-side {
                        width: 70%;
                    }
                    @media screen and (max-width: $size-sm-max) {
                        .image-side, .text-side {
                            width: 100%;
                        }
                        @media screen and (max-width: $size-xs-max) {
                            .image-side {
                                padding: 30px;
                            }
                            .text-side {
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-image-text {
    padding: 100px 5% 50px 10%;
    margin-top: 75px;
    position: relative;
    .image-side {
        width: 45%;
        margin-right: 50px;
    }
    .text-side {
        width: 55%;
        .logo {
            justify-content: right;
            padding-bottom: 50px;
        }
        .text {
            h3 {
                font-weight: bold;
                font-size: $font-size-60;
                color: $color-2;
                padding-bottom: 35px;
            }
            p {
                width: 83%;
                color: #063448;
            }
            .section-list {
                padding: 35px 0 0 50px;
                h4 {
                    font-weight: 500;
                    font-size: $font-size-20;
                    color: #063448;
                }
            }
        }
    }
    &::before {
        content: '';
        width: 37%;
        height: 82%;
        background: $color-1;
        position: absolute;
        left: 5%;
        top: 0;
        z-index: -1;
    }
    @media screen and (max-width: 1825px) {
        .text-side .text p {
            width: 90%;
        }
        @media screen and (max-width: 1740px) {
            padding: 85px 5% 50px 10%;
            .text-side {
                .text {
                    h3 {
                        font-size: 2.7rem;
                    }
                    p {
                        width: 100%;
                    }
                }
            }
            @media screen and (max-width: 1600px) {
                padding: 70px 5% 50px 10%;
                .text-side {
                    .text {
                        h3 {
                            font-size: 2.5rem;
                        }
                    }
                }
                &::before {
                    height: 78%;
                }
                @media screen and (max-width: 1530px) {
                    .text-side {
                        .text {
                            h3 {
                                font-size: 2.3rem;
                            }
                        }
                    }
                     &::before {
                        height: 72%;
                    }
                    @media screen and (max-width: 1410px) {
                        flex-direction: column;
                        margin-top: 0;
                        padding: 50px 5% 75px 5%;
                        .image-side {
                            width: 65%;
                            margin: 0 auto 50px auto;
                        }
                        .text-side {
                            width: 100%;
                            .logo {
                                padding-bottom: 35px;
                            }
                            h3 br {
                                display: none;
                            }
                            &.pt12 {
                                padding: 0;
                            }
                        }
                        &::before {
                            display: none;
                        }
                        @media screen and (max-width: 1024px) {
                            .image-side {
                                    width: 70%;
                            }
                            @media screen and (max-width: $size-sm-max) {
                                .image-side {
                                    width: 100%;
                                }
                                @media screen and (max-width: $size-xs-max) {
                                    .text-side {
                                        text-align: center;
                                        .text .section-list {
                                            padding: 29px 0 0 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-logo {
    padding: 75px 5%;
    @media screen and (max-width: $size-xs-max) {
        flex-wrap: wrap;
        .logo {
            width: 50%;
            margin: 0;
            padding: 25px;
        }
    }
}

/*******************************************************************************
* NOS TECHNOLOGIES
*******************************************************************************/

#section-intro-text {
    padding: 50px 0;
    margin: 25px 5% 0 5%;
    border-top: 1px solid $color-3;
    .left-side {
        width: 50%;
        margin-right: 5%;
        h3 {
            font-weight: bold;
            font-size: $font-size-60;
            color: $color-2;
        }
    }
    .right-side {
        width: 50%;
        p {
            color: #063448;
        }
    }
    @media screen and (max-width: 1750px) {
        .left-side {
            margin-right: 1%;
            h3 {
                font-size: 2.7rem;
            }
        }
        @media screen and (max-width: 1510px) {
            .left-side {
                h3 {
                    font-size: 2.4rem;
                }
            }
            @media screen and (max-width: 1200px) {
                flex-direction: column;
                .left-side {
                    width: 100%;
                    margin: 0 0 15px 0;
                }
                .right-side {
                    width: 100%;
                }
                @media screen and (max-width: $size-xs-max) {
                    text-align: center;
                    .left-side {
                        h3 {
                            font-size: 2.1rem;
                            br {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-technologie {
    padding: 0 5%;
    .image {
        margin-bottom: 30px;
    }
    .left-side, .right-side {
        margin-bottom: 75px;
        h5 {
            font-weight: 600;
            font-size: $font-size-16;
            color: #063448;
            text-transform: uppercase;
        }
    }
    &:nth-child(4n) {
        border-bottom: 1px solid $color-2;
        padding: 0;
        margin: 0 5% 65px 5%;
    }
    @media screen and (max-width: 1825px) {
        .left-side {
            margin-right: 15px;
        }
        @media screen and (max-width: $size-sm-max) {
            flex-direction: column;
            @media screen and (max-width: $size-xs-max) {
                .left-side, .right-side {
                    margin-bottom: 50px;
                }
            }
        }
    }
}

/*******************************************************************************
* SERVICES - INSTITUTIONNEL
*******************************************************************************/

#section-intro.services {
    padding: 75px 5%;
    text-align: center;
    width: 90%;
    margin: -70px auto 0 auto;
    position: relative;
    background: #fff;
    h3 {
        padding-bottom: 35px;
    }
    p {
        width: 93%;
    }
    @media screen and (max-width: 1530px) {
        p br {
            display: none;
        }
        @media screen and (max-width: 1200px) {
            width: 100%;
            margin: 0;
            p {
                width: 100%;
            }
        }
    }
}

#section-carousel {
    padding: 100px 5% 75px 5%;
    position: relative;
    .main-content {
        .item {
            .col-xs-12.txt {
                padding-left: 0;
                padding-right: 0;
                h4 {
                    font-size: $font-size-16;
                    color: #202124;
                    padding: 15px 0 0 15px;
                }
            }
        }
        .owl-nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            right: 0;
            top: -85px;
            width: 100%;
            z-index: 1;
            .owl-prev {
                padding-right: 35px;
            }
        }           
    }
    a.btn-link {
        width: 250px;
        margin: 75px auto 0 auto;
    }
    @media screen and (max-width: $size-xs-max) {
        a.btn-link {
            width: 195px;
            margin: 50px auto 0 auto;
        }
    }
}

/*******************************************************************************
* SERVICES - COMMERCIAL
*******************************************************************************/

#section-image-text.services {
    background: $color-1;
    margin: 75px 0 175px 5%;
    padding: 0 0 0 3%;
    .image-side {
        width: 50%;
        background: #C2EBFD;
        padding: 35px;
        position: relative;
        bottom: -100px;
    }
    .text-side {
        margin-right: 10%;
        margin-top: 50px;
        h3 {
            font-weight: bold;
            font-size: $font-size-60;
            color: #71C5F1;
            padding-bottom: 25px;
        }
        p {
            color: $color-white;
        }
    }
    &::before {
        display: none;
    }
    @media screen and (max-width: 1600px) {
        margin: 75px 0 125px 5%;
        .image-side {
            bottom: -50px;
        }
        .text-side h3 {
            font-size: 2.7rem;
        }
        @media screen and (max-width: 1475px) {
            .text-side {
                margin-top: 0;
            }
            @media screen and (max-width: 1410px) {
                flex-direction: initial;
                .image-side {
                    margin-right: 25px;
                }
                .text-side {
                    width: 55%;
                }
                @media screen and (max-width: 1350px) {
                    flex-direction: column;
                    background: none;
                    margin: 0;
                    padding: 75px 5%;
                    .image-side {
                        position: initial;
                        margin: 0;
                        width: 60%;
                    }
                    .text-side {
                        width: 100%;
                        background: #145D88;
                        padding: 50px 5%;
                        margin: 0;
                    }
                    @media screen and (max-width: 1024px) {
                        .image-side {
                            width: 70%;
                        }
                        @media screen and (max-width: $size-sm-max) {
                            .image-side {
                                width: 100%;
                            }
                            @media screen and (max-width: $size-xs-max) {
                                .image-side {
                                    padding: 25px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* SERVICE - INDUSTRIEL
*******************************************************************************/

#section-citation {
    background: #C2EBFD39;
    padding: 150px 10%;
    margin: 50px 0 -50px 0;
    position: relative;
    h3 {
        font-weight: bold;
        font-size: $font-size-60;
        color: $color-2;
        font-style: italic;
        padding-bottom: 25px;
    }
    h4 {
        font-weight: 600;
        font-size: $font-size-30;
        color: $color-3;
        font-style: italic;
        line-height: 1.3;
    }
    .quote {
        position: absolute;
        top: 5%;
        left: 5%;
    }
    @media screen and (max-width: 1725px) {
        h3 br {
            display: none;
        }
        @media screen and (max-width: 1615px) {
            h4 br {
                display: none;
            }
            @media screen and (max-width: 1024px) {
                padding: 150px 5% 100px 5%;
                h3 {
                    font-size: 2.5rem;
                }
                h4 {
                    font-size: 1.3rem;
                }
                .quote {
                    top: 0%;
                    left: 0%;
                }
                @media screen and (max-width: $size-xs-max) {
                    padding: 75px 5%;
                    margin: 35px 0 0 0;
                    text-align: center;
                    h3 {
                        font-size: 2.3rem;
                    }
                    h4 {
                        font-size: 1.2rem;
                    }
                    .quote {
                        display: none;
                    }
                    @media screen and (max-width: 375px) {
                        h3 {
                            font-size: 2rem;
                        }
                    }
                }
            }
        }
    }
}

#section-left-right {
    padding: 50px 5% 25px 0;
    .text-side {
        background: #C2EBFD55;
        width: 55%;
        padding: 0 50px 0 5%;
        margin-right: 25px;
        h3 {
            font-weight: bold;
            font-size: $font-size-60;
            color: $color-2;
            padding-bottom: 25px;
        }
        p {
            color: #063448;
        }
    }
    .image-side {
        width: 45%;
        background: url("../images/industriel_section03_img01.png") no-repeat;
        background-size: cover;
    }
    @media screen and (max-width: 1024px) {
        flex-direction: column-reverse;
        padding: 50px 5% 25px 5%;
        .image-side {
            width: 70%;
            margin: 0 auto 20px auto;
        }
        .text-side {
            width: 70%;
            padding: 50px 5%;
            margin: 0 auto;
        }
        @media screen and (max-width: $size-sm-max) {
            .image-side, .text-side {
                width: 100%;
            }
            @media screen and (max-width: $size-xs-max) {
                .image-side {
                    background: url("../images/industriel_section03_img01_m.jpg") no-repeat;
                    background-size: cover;
                }
                .text-side {
                    h3 {
                        font-size: 2.5rem;
                    }
                }
            }
        }
    }
}

#section-text-image.industriel {
    .text-side {
        h5 {
            font-weight: 500;
            font-size: $font-size-20;
            font-style: italic;
            color: #063448;
            line-height: 1.5;
            padding: 20px 0;
        }
    }
    .image-side {
        background: linear-gradient(to right, #c2ebfd 0%, #c2ebfd 10%, #71C5F1 10%, #71C5F1 100%);
    }
    @media screen and (max-width: 1675px) {
        .text-side h3 {
            font-size: 2.5rem;
        }
        @media screen and (max-width: 1575px) {
            .text-side {
                p, h5 {
                    width: 100%;
                    font-size: 0.9rem;
                }
            }
            @media screen and (max-width: 1440px) {
                .text-side h3 {
                    font-size: 2.3rem;
                }
                @media screen and (max-width: 1400px) {
                    flex-direction: column-reverse;
                    padding: 50px 5%;
                    .image-side {
                        padding: 75px;
                        width: 65%;
                        margin: auto;
                        background: #71C5F1;
                    }
                    .text-side {
                        background: #C2EBFD2C;
                        width: 65%;
                        margin: auto;
                        padding: 50px 5%;
                        h5 {
                            font-size: 1rem !important;
                        }
                    }
                    @media screen and (max-width: 1024px) {
                        .image-side, .text-side {
                            width: 70%;
                        }
                        @media screen and (max-width: $size-sm-max) {
                            .image-side, .text-side {
                                width: 100%;
                            }
                            @media screen and (max-width: $size-xs-max) {
                                .image-side {
                                    padding: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-text-image.reverse {
    padding: 50px 5% 100px 5%;
    .image-side {
        width: 45%;
        background: linear-gradient(to left, #C2EBFD2C 0%, #C2EBFD2C 10%, #71C5F1 10%, #71C5F1 100%);
        padding-top: 85px;
        padding-left: 85px;
        padding-right: 0;
    }
    .text-side {
        background: #C2EBFD2C;
        width: 55%;
        padding: 0 5% 0 50px;
    }
    @media screen and (max-width: 1790px) {
        .text-side h3 {
            br {
                display: none;
            }
        }
        @media screen and (max-width: 1600px) {
            .text-side h3 {
                font-size: 2.7rem;
            }
            @media screen and (max-width: 1500px) {
                .text-side h3 {
                    font-size: 2.5rem;
                }
                @media screen and (max-width: 1200px) {
                    flex-direction: column;
                    .image-side {
                        padding: 75px;
                        width: 65%;
                        background: #71C5F1;
                    }
                    .text-side {
                        background: #C2EBFD2C;
                        width: 65%;
                        padding: 50px 5%;
                    }
                    @media screen and (max-width: 1024px) {
                        .image-side, .text-side {
                            width: 70%;
                        }
                        @media screen and (max-width: $size-sm-max) {
                            .image-side, .text-side {
                                width: 100%;
                            }
                            @media screen and (max-width: $size-xs-max) {
                                .image-side {
                                    padding: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

section.gallerie-products {
    padding: 125px 5% 0 5%;

    .section-gallerie {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .grid-item {
            margin: 7px;
            width: 32%;
            min-width: 250px;
            img {
                width: 100%;
            }
            &:last-child {
                margin-right: 0;
            }
        }

        .header-gallery-section {
            width: 97.5%;
            flex: none;
            padding-top: 50px;
            padding-bottom: 25px;
            margin-left: 7px;
            h4 {
                font-weight: bold;
                font-size: $font-size-40;
                color: $color-2 !important;
                text-transform: uppercase;
            }
            .border {
                background: $color-2;
                height: 3px;
                width: 80%;
                margin-left: 5%;
            }
            &:first-child {
                a#category9 {
                    padding: 30px 0;
                }
                a.scroll-link img {
                    display: none;
                }
            }
        }

        @media screen and (max-width: $size-md-max){
            .grid-item {
                width: 48%;
            }
            @media screen and (max-width: $size-sm-max){
                .header-gallery-section {
                    width: 96%;
                }
                .grid-item {
                    width: 47%;
                }
                @media screen and (max-width: $size-xs-max){
                    .grid-item {
                        width: 100%;
                        margin: 0 0 10px 0;
                    }
                }
            }
        }
        @media screen and (max-width: 600px){
            flex-direction: column;
            justify-content: center;
        }
    }
}

#section-btn-up {
    padding: 25px 5% 50px 5%;
    display: flex;
    justify-content: right;
}

/*******************************************************************************
* CARRIÈRE
*******************************************************************************/

#section-info {
    padding: 50px 0;
    margin: 0 5%;
    .left-side {
        width: 45%;
        margin-right: 6%;
        
    }
    .right-side {
        width: 45%;
    }
    p {
        color: #063448;
    }
    @media screen and (max-width: 1210px){
        @media screen and (max-width: 1100px){
            flex-direction: column;
            .left-side {
                width: 100%;
                margin: 0 0 35px 0;
            }
            .right-side {
                width: 100%;
            }
        }
    }
}

#section-emploi {
    padding: 0 5% 50px 5%;
    div.section-wrapper {
        display: inline-grid;
        margin: auto;
        width: 90%;
        max-width: 1600px;;
        justify-items: stretch;
        grid-template-columns: 1fr 1fr;
        column-gap: 10px;
        @media screen and (max-width: $size-sm-max){
            grid-template-columns: 1fr;
        }
    }
    div.left-side, div.right-side {
        width: 50%;
        @media screen and (max-width: $size-xs-max){
          width: 100%;
        }
    }

    section.emplois-liste {
        display: flex;
        flex-wrap: wrap;
        div.item-poste {
            display: inline-block;
            margin-bottom: 20px;
            background-color: #C2EBFD;
            transition: .5s;
            width: 49%;
            padding-bottom: 50px;

            div.title {
                background-color: $color-1;
                padding: 30px 7%;
                h4 {
                    color: #F2F2F2;
                    font-size: 20px;
                    font-weight: bold;
                    text-transform: initial;
                }
                @media screen and (max-width: 1325px){
                    padding: 30px 5%;
                }
            }
            div.soustitre {
                padding: 30px 7%;
                .icon {
                    font-size: 18px;
                    font-weight: bold;
                    text-transform: uppercase;
                    color: $color-2;
                    margin-right: 50px;
                    padding-bottom: 10px;
                    .fa {
                        font-size: 20px;
                        margin-right: 10px;
                        color: $color-2;
                    }
                    @media screen and (max-width: 1420px){
                        margin-right: 25px;
                        @media screen and (max-width: 1270px){
                            margin-right: 0;
                            width: 100%;
                            margin-bottom: 15px;
                            @media screen and (max-width: 1110px){
                                margin-right: 25px;
                                width: initial;
                                margin-bottom: 0;
                                @media screen and (max-width: $size-xs-max){
                                    margin-right: 0;
                                    width: 100%;
                                    margin-bottom: 15px;
                                }
                            }
                        }
                    }
                }
                @media screen and (max-width: 1325px){
                    padding: 30px 5%;
                }
            }
            div.content-full {
                padding: 0 7% 30px 7%;
                h4, h3, h2 {
                    font-size: 18px;
                    color: $color-2;
                    padding: 15px 0;
                    font-weight: bold;
                }
                p, li {
                    color: #063448;
                    font-weight: normal;
                    font-size: 16px;
                    padding-left: 25px;
                    text-transform: initial;
                    line-height: 1.3;
                    padding-bottom: 10px;
                    @media screen and (max-width: 1420px){
                        padding-left: 0;
                    }
                }
                .btn-wrapper {
                    max-width: 200px;
                    margin-top: 50px;
                    .btn {
                        padding: 25px 5px;
                        background-color: $color-2;
                        border: none;
                        border-radius: 5px;
                        font-weight: bold;
                        color: #C2EBFD;
                        font-size: 20px;
                        transition: 0.3s;
                        &:hover {
                            background: #71C5F1;
                            color: $color-1;
                            transition: 0.3s;
                        }
                    }
                }
                @media screen and (max-width: 1325px){
                    padding: 0 5% 30px 5%;
                }
            }
            &:nth-child(odd) {
                margin-right: 20px;
                @media screen and (max-width: 1110px){
                    margin-right: 0;
                }
            }
            @media screen and (max-width: 1110px){
                width: 100%;
                margin: 0 0 15px 0;
            }
        }
    }
}

section.section-form-emploi {
    padding: 60px 10% 25px 10%;
    margin-bottom: 60px;
    background-color: #C2EBFD;
    h3 {
        font-weight: bold;
        color: $color-2;
        font-size: $font-size-30;
        line-height: 1.3;
        padding-bottom: 20px;
        @media screen and (max-width: 360px) {
            font-size: 1.4rem;
        }
        @media screen and (max-width: 1545px){
            br {
                display: none;
            }
        }
    }
    .form-group {
        margin-bottom: -10px;
    }
    input {
        height: 95px;
        border: 1px solid #063448;
    }
    textarea#message {
        border: 1px solid #063448;
    }
    input::placeholder, textarea::placeholder {
        font-weight: normal;
        font-size: $font-size-16;
        color: $color-1; 
    }
    textarea::placeholder {
        padding: 15px 5px !important;
    }
    form label.filedoc {
        padding: 25px 5px;
        background-color: $color-2;
        border: none;
        border-radius: 5px;
        font-weight: bold;
        color: #C2EBFD;
        font-size: 20px;
        transition: 0.3s;
        &:hover {
            background: #71C5F1;
            color: $color-1;
            transition: 0.3s;
        }
    }
    .btn {
        padding: 25px 5px;
        background-color: $color-2;
        border: none;
        border-radius: 5px;
        font-weight: bold;
        color: #C2EBFD;
        font-size: 20px;
        transition: 0.3s;
        &:hover {
            background: #71C5F1;
            color: $color-1;
            transition: 0.3s;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        .pl0 , .pr0, {
            padding: 0!important;
        }
        form label.filedoc {
            width: 230px;
            margin-right: 0;
        }
        form .buttons {
            justify-content: center;
            margin: 0 0 50px 0;
        }
        .btn-submit {
            margin-top: 15px;
            margin-right: 0;
            min-width: initial !important;
            .btn.submit-emploi {
                width: 230px;
            }
        }
    }
    @media screen and (max-width: 1325px){
        padding: 60px 8% 25px 8%;
        @media screen and (max-width: $size-sm-max){
            padding: 60px 5% 25px 5%;
        }
    }
}

/*******************************************************************************
* NOUS JOINDRE
*******************************************************************************/

#section-coordonnees {
    padding: 0 5% 100px 5%;
    .form-info {
        position: relative;
        margin-top: -250px;
    }
    .section-form {
        width: 65%;
        margin-right: 25px;
        background: $color-white;
        box-shadow: 0px 0px 6px #00000017;
        padding: 65px 100px 50px 100px;
        h3 {
            font-weight: bold;
            font-size: $font-size-40;
            color: $color-2;
            padding-bottom: 10px;
        }
        p {
            padding-bottom: 35px;
            @media screen and (max-width: $size-xs-max) {
                text-align: center;
            }
            @media screen and (max-width: 1630px) {
                br {
                    display: none;
                }
            }
        }
        .form-group {
            margin-bottom: -15px;
        }
    }
    .text-side {
        width: 35%;
        padding: 65px 45px 35px 45px;
        background: $color-1;
        h2 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #C2EBFD;
            padding-bottom: 50px;
            @media screen and (max-width: 1820px) {
                br {
                    display: none;
                }
            }
        }
        .section-info {
            .text strong {
                font-weight: bold;
                color: #C2EBFD;
            }
            .text {
                width: 100%;
                margin-left: 25px;
                h3 {
                    font-weight: bold;
                    font-size: $font-size-24;
                    color: #C2EBFD;
                    text-transform: initial;
                    padding-bottom: 10px;
                }
                h4 {
                    font-weight: normal;
                    font-size: $font-size-18;
                    color: #C2EBFD;
                }
                h5 {
                    font-weight: normal;
                    font-size: $font-size-18;
                    color: #C2EBFD;
                    padding-bottom: 0;
                }
                .jours-heures {
                    padding-top: 10px;
                    h4, h5 {
                        font-weight: bold;
                        font-size: $font-size-18;
                        color: #C2EBFD;
                        padding-bottom: 0;
                        line-height: 2;
                    }
                    h5 {
                        font-weight: normal;
                        line-height: 2;
                    }
                    .jour.mr8 {
                        width: 55%;
                    }
                    &.anglais .jour.mr8 {
                        @media screen and (max-width: 1890px) {
                            width: 40%;
                            @media screen and (max-width: 1535px) {
                                width: 35%;
                            }
                        }
                    }
                }
            }
        }
        &.anglais {
            @media screen and (max-width: 1535px) {
                padding: 65px 25px 35px 25px;
                @media screen and (max-width: 1325px) {
                    padding: 65px 15px 35px 15px;
                    @media screen and (max-width: $size-md-max) {
                        padding: 65px 45px 35px 45px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1515px) {
        .text-side h2 {
            text-align: center;
        }
        @media screen and (max-width: 1425px) {
            .text-side {
                padding: 65px 35px 35px 35px;
            }
            @media screen and (max-width: 1365px) {
                .text-side {
                    .section-info {
                        .text {
                            .jours-heures {
                                .jour.mr8 {
                                    width: 45%;
                                }
                            }
                        }
                    }
                }
                @media screen and (max-width: $size-md-max) {
                    .form-info {
                        flex-direction: column-reverse;
                        margin-top: -150px !important;
                        .text-side {
                            width: 75%;
                            margin: auto;
                            .section-info {
                                .text {
                                    .jours-heures {
                                        .jour.mr8 {
                                            width: 30%;
                                        }
                                    }
                                }
                            }
                        }
                        .section-form {
                            width: 85%;
                            margin: 35px auto 0 auto;
                        }
                    }
                    @media screen and (max-width: 1024px) {
                        .form-info {
                            .text-side {
                                width: 85%;
                                padding: 65px 45px;
                            }
                            .section-form {
                                width: 100%;
                            }
                        }
                        @media screen and (max-width: $size-sm-max) {
                            .form-info {
                                margin-top: -95px !important;
                                .text-side {
                                    width: 90%;
                                }
                                .section-form {
                                    padding: 65px 50px 50px 50px;
                                }
                            }
                            @media screen and (max-width: $size-xs-max) {
                                .form-info {
                                    margin-top: -130px !important;
                                    .text-side {
                                        width: 100%;
                                        padding: 50px 25px;
                                    }
                                    .section-form {
                                        padding: 50px 25px;
                                        .form-group.col-xs-12.col-sm-6.pl0 {
                                            padding: 0;
                                        }
                                        h3 {
                                            text-align: center;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-map {
    padding: 75px 5%;
    .map-left {
        width: 50%;
        margin-right: 25px;
    }
    .map-right {
        width: 50%;
    }
}

div#content {
    h3#firstHeading {
        font-size: $font-size-28;
        padding-bottom: 10px;
        color: $color-2;
    }
    p a {
        color: $color-1;
    }
}










/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
   

}

@media screen and (max-width: $size-sm-max) {
   

}

@media screen and (max-width: $size-xs-max) {
   

}
