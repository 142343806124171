.slideshow-wrap {
    position: relative;
    .caption {
        position: absolute;
        top: 68%;
        left: 55%;
        width: 100%;
        transform: translateX(-50%);
        z-index: $z-index-slide-caption;
    }

    .slideshow {
      	width: 100%;
      	overflow: hidden;
      	position: relative;
      	.placeholder {
        		width: 100vw;
        		height: auto;
        		display: block;
            @media screen and (max-width: $size-slider-breakpoint){
                height: calc(100vw / 480 * 600);
            }
      	}
        &.mobile {
            display: none;
        }
      	.slide {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            width: 100vw;
            overflow: hidden;
            position: absolute;
            backface-visibility: hidden;
            transition: 1.5s;

            &_wrapper {
                position: relative;
                .layer-inner {
                    left: 5%;
                    bottom: 20%;
                    width: 90%;
                    position: absolute;

                    h2 {
                        font-weight: bold;
                        font-size: $font-size-60;
                        color: #FAFAFA;
                        text-transform: uppercase;
                        @media screen and (max-width: 375px) {
                          font-size: 2.6rem;
                        }
                    }
                }
            }
        		img {
        			 width: 100vw!important;
        			 height: auto;
        			 display: block;
        			 position: relative;
        		}
            &.transiting {
                left: 0;
                right: 0;
                opacity: 1;
                transition: 1.5s;
        		}
        		&.active {
          			right: 0;
                left: 0;
          			z-index: 10;
                opacity: 1;
                transition: 1.5s;
        		}
        	}
        	@media screen and (max-width: 480px) {
              &.mobile {
                  display: block;
              }
              &.ecran {
                  display: none;
              }
        	}
      }
      @media screen and (max-width: $size-slider-breakpoint) {
          .caption {
              top: $size-nav-height-mobile + 20px;
          }
      }
}
