.btn {
    background-color: $color-2;
    color: #C2EBFD;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    font-size: $font-size-20;
    width: 190px;
    height: 85px;
    transition: all 0.2s ease-in;

    &:hover {
        transition: all 0.2s ease-in;
        background-color: #71C5F1;
        color: $color-1;
    }
}
