.module-header {
    position: relative;

    &--image {}

    &--image-mobile {}

    &--caption {
        position: absolute;
        top: 85%;
        left: 5%;
        width: 45%;
        background: rgba(20,93,136,0.8);
        padding: 50px 50px 75px 50px;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        transform: translateY(-50%);

        &--title {
            width: 100%;
            text-align: center;
            font-size: 5vw;
            font-weight: 400;
            color: $color-text-contrast;
            text-shadow: 0 0 10px $color-black;
        }
        &--subtitle {
            width: 100%;
            font-size: $font-size-20;
            font-weight: normal;
            line-height: 1.5;
            color: #FEF3F4;
            //text-shadow: 0 0 10px $color-black;
        }
    }
}
@media screen and (max-width: 1750px) {
    .module-header {
        &--caption {
            top: 83%;
        }
    }
    @media screen and (max-width: 1715px) {
        .module-header {
            &--caption {
                width: 48%;            }
        }
        @media screen and (max-width: 1600px) {
            .module-header {
                &--caption {
                    top: 80%;
                    width: 50%;
                }
            }
            @media screen and (max-width: 1450px) {
                .module-header {
                    &--caption {
                        width: 60%;
                    }
                }
                @media screen and (max-width: 1150px) {
                    .module-header {
                        &--caption {
                            width: 68%;
                        }
                    }
                    @media screen and (max-width: 1024px) {
                        .module-header {
                            &--caption {
                                top: 80%;
                                width: 75%;
                                padding: 50px;
                            }
                        }
                        @media screen and (max-width: $size-sm-max) {
                            .module-header {
                                &--caption {
                                    top: 90%;
                                    width: 87%;
                                    padding: 35px;
                                }
                            }
                            @media screen and (max-width: $size-xs-max) {
                                .module-header {
                                    &--caption {
                                        width: 90%;
                                        &--subtitle {
                                           
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


.module-header.no-caption .module-header--caption {
    background: initial;
    backdrop-filter: none;
}