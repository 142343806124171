footer {
    min-height: $size-footer-height;
    position: relative;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: $size-footer-width-desktop;
        margin: 0 auto;
        @extend .flex;
        @extend .flex-wrap;
        @extend .items-center;
        @extend .justify-around;
        @extend .text-color-grey;
        @extend .font-footer;
        flex-direction: column;
        .copyright {
            font-weight: normal;
            font-size: $font-size-18;
            color: #C2EBFD;
            margin-bottom: 20px;
        }
        .icon-reseaux {
            position: absolute;
            right: 5%;
        }
        @media screen and (max-width: $size-xs-max) {
            width: 65%;
            .copyright {
                margin-bottom: 15px;
            }
            .icon-reseaux {
                position: initial;
                padding-top: 25px;
            }
        }
    }
}
